<template>
  <div class="tab-pane active" id="short-codes">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <router-link
            :to="`/admin/customers/${customer.id}/short-codes/create`"
            class="btn btn-success btn-floating"
          >
            <i
              class="
                fas
                fa-plus-circle
                text-white
                font-size-22
                v-align-text-bottom
              "
            ></i>
          </router-link>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>{{ $t('adm.customer.associated-in') }}</th>
              <th>{{ $tc('generic-str.lbl-number', 1) }}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr v-for="shortCode in shortCodes" :key="shortCode.id">
              <td>
                {{ shortCode.created_at | date('DD/MM/YYYY HH:mm:ss') }}
              </td>
              <td>{{ shortCode.name }}</td>
              <td class="action">
                <div class="btn-group">
                  <router-link
                    :to="`/admin/customers/${customer.id}/short-codes/${shortCode.id}`"
                    class="btn btn-primary"
                  >
                    <span class="icon dripicons-pencil"></span>
                  </router-link>
                  <button
                    @click="unsign(shortCode.id)"
                    type="button"
                    class="btn btn-danger"
                  >
                    <span class="icon dripicons-trash"></span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Swal from 'sweetalert2';
import Tab from '@/directives/Tab';
import SmsService from '@/services/admin/sms.service';
import CustomerService from '@/services/customer.service';
import { mask } from 'vue-the-mask';

export default {
  name: 'CustomerShortCodes',
  directives: {
    Tab,
    mask,
  },
  props: ['customer'],
  data() {
    return {
      fetched: false,
      isSending: false,
      shortCodes: [],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 4,
        masked: false,
      },
      product: {},
      products: [],
      form: {
        billing: 'monthly',
        currency: 'brl',
        product: {},
        values: {},
      },
      attaching: false,
      pages: 1,
    };
  },
  created() {
    this.fetchShortCodes();
  },
  methods: {
    fetchShortCodes() {
      SmsService.getShortCodes({
        account_id: this.$route.params.customerId,
      })
        .then(
          (response) => {
            this.shortCodes = response;
            this.pages = response.last_page;
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
                error.code
              }`,
              content: error.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
    unsign(shortCodeId) {
      Swal.fire({
        title: 'Remover Short Code',
        text: 'O Short Code será removido da conta. Tem certeza que deseja fazer isso?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.fetched = false;
          CustomerService.unsignShortCode(
            this.$route.params.customerId,
            shortCodeId,
          ).then(
            () => {
              this.fetchShortCodes();
              this.$toast.show({
                title: 'Sucesso',
                content: 'Short Code removido',
                type: 'success',
              });
            },
            (error) => {
              this.$toast.show({
                title: 'Erro',
                content: error.message,
                type: 'danger',
              });
              this.fetched = true;
            },
          );
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.loading-cep {
  right: 40px;
  top: 40px;
  position: absolute;
  padding: initial;
}
.select-container {
  display: block;
}

</style>
